import React from 'react'
import tw from 'twin.macro'

const PlusPersonal = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.4001 16.1999C15.8323 16.1999 17.2058 15.631 18.2185 14.6183C19.2312 13.6056 19.8001 12.2321 19.8001 10.7999C19.8001 9.36773 19.2312 7.99422 18.2185 6.98153C17.2058 5.96883 15.8323 5.3999 14.4001 5.3999C12.9679 5.3999 11.5944 5.96883 10.5817 6.98153C9.56902 7.99422 9.0001 9.36773 9.0001 10.7999C9.0001 12.2321 9.56902 13.6056 10.5817 14.6183C11.5944 15.631 12.9679 16.1999 14.4001 16.1999ZM14.4001 19.7999C17.2644 19.7999 20.0115 20.9378 22.0368 22.9632C24.0622 24.9885 25.2001 27.7356 25.2001 30.5999H3.6001C3.6001 27.7356 4.73795 24.9885 6.76334 22.9632C8.78874 20.9378 11.5358 19.7999 14.4001 19.7999ZM28.8001 12.5999C28.8001 12.1225 28.6105 11.6647 28.2729 11.3271C27.9353 10.9895 27.4775 10.7999 27.0001 10.7999C26.5227 10.7999 26.0649 10.9895 25.7273 11.3271C25.3897 11.6647 25.2001 12.1225 25.2001 12.5999V14.3999H23.4001C22.9227 14.3999 22.4649 14.5895 22.1273 14.9271C21.7897 15.2647 21.6001 15.7225 21.6001 16.1999C21.6001 16.6773 21.7897 17.1351 22.1273 17.4727C22.4649 17.8103 22.9227 17.9999 23.4001 17.9999H25.2001V19.7999C25.2001 20.2773 25.3897 20.7351 25.7273 21.0727C26.0649 21.4103 26.5227 21.5999 27.0001 21.5999C27.4775 21.5999 27.9353 21.4103 28.2729 21.0727C28.6105 20.7351 28.8001 20.2773 28.8001 19.7999V17.9999H30.6001C31.0775 17.9999 31.5353 17.8103 31.8729 17.4727C32.2105 17.1351 32.4001 16.6773 32.4001 16.1999C32.4001 15.7225 32.2105 15.2647 31.8729 14.9271C31.5353 14.5895 31.0775 14.3999 30.6001 14.3999H28.8001V12.5999Z"
            fill="currentColor"
        />
    </svg>
)

export default PlusPersonal
