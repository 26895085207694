/* eslint-disable no-nested-ternary */
import React from 'react'
import { Layout } from '../../components/layout'
import Meta from '../../components/layout/meta'
import { RelativeContainer, ComponentContainer, ColoredBackgroundWithProps } from '../../components/shared/landingPages/layoutComponents'
import Header from '../../components/shared/landingPages/header'
import TopHighlights from '../../components/shared/landingPages/topHighlights'
import FeatureLinks from '../../components/shared/landingPages/featureLinks'
import Benefits from '../../components/shared/landingPages/benefits'
import HeaderAdvancedFunctionality from '../../components/shared/landingPages/headerAdvancedFunctionality'
import AdvancedFunctionality from '../../components/shared/landingPages/advancedFunctionality'
import Bag from '../../images/offertelp/bag.js'
import Book from '../../images/offertelp/book.js'
import PlusPersonal from '../../images/offertelp/plusPersonal.js'
import Clear from '../../images/offertelp/clear.js'
import Credit from '../../images/icons/credit.js'
import header from '../../images/offertelp/header.png'
import professional from '../../images/offertelp/professional.png'
import overview from '../../images/offertelp/overview.png'
import benefit from '../../images/offertelp/benefits.png'
import { Accordeon } from '../../components/UI'
import { Title } from '../../components/shared/landingPages/title'

const headerData = {
    title: `Gemakkelijk offertes maken en goedkeuren`,
    text: `Creëer in een handomdraai professionele offertes in de stijl van jouw bedrijf en verstuur ze direct aan je klant. Houd de voortgang van de offerte bij in het handige overzicht. Als de offerte is geaccepteerd kan je deze direct omzetten in een factuur.`,
    headerImage: header,
    textCTA: `Gebruik Tellow gratis`,
}

const topHighlightData = {
    title: `Offertes maken met Tellow`,
    points: [
        {
            Icon: PlusPersonal,
            title: `Altijd compleet`,
            body: `
            De offertes die je verstuurt via Tellow zijn volledig en voldoen aan alle eisen. Een uniek offertenummer, de voorwaarden, het btw-tarief en alle andere verplichte gegevens staan op de offerte.
        `,
        },
        {
            Icon: Clear,
            title: `Gemakkelijk en snel`,
            body: `
            Gebruik Tellow online via desktop of via onze handige app. Je eerste offerte versturen is zo gepiept.
        `,
        },
        {
            Icon: Book,
            title: `Professionele uitstraling`,
            body: `
            Maak gemakkelijk offertes met een professionele uitstraling. Kies uit een van onze standaard templates of ontwerp een offerte met je eigen logo en bedrijfskleuren.
        `,
        },
        {
            Icon: Bag,
            title: `Alles bij de hand `,
            body: `
            Voeg eenvoudig de algemene voorwaarden of andere specificaties toe als bijlage. Zo heeft je klant alle benodigde informatie bij de hand.
        `,
        },
    ],
}

const benefitsData = {
    points: [
        {
            title: 'Altijd compleet',
            body: 'De offertes die je verstuurt via Tellow zijn volledig en voldoen aan alle eisen. Een uniek offertenummer, de voorwaarden, het btw-tarief en alle andere verplichte gegevens staan op de offerte.',
            links: 'Stuur je eerste offerte',
            url: `https://app.tellow.nl/registreer#/`,
        },
        {
            title: 'Stroomlijn je offertes',
            body: 'Een professionele offerte opmaken en versturen naar potentiële klanten is zo geregeld. Na goedkeuring wordt de offerte automatisch omgezet in een factuur.',
            links: 'Maak een offerte op',
            url: `https://app.tellow.nl/registreer#/`,
        },
    ],
    benefitImage: benefit,
    altImage: `Benefit Image`,
}

const headerAdvancedFunctionalityData = {
    title: `Gemakkelijk en snel`,
    subtitlePartOne: `Gebruik Tellow online via desktop of via onze handige app.`,
    subtitlePartTwo: `Je eerste offerte versturen is zo gepiept.`,
}

const advancedFunctionalityData = {
    firstAnnouncer: `Alles bij de hand`,
    firstDescription: `Voeg eenvoudig de algemene voorwaarden of andere specificaties toe als bijlage. Zo heeft je klant alle benodigde informatie bij de hand.`,
    getMoneyOneImage: overview,
    secondAnnouncer: `Professionele uitstraling`,
    secondDescription: `Maak gemakkelijk offertes met een professionele uitstraling. Kies uit een van onze standaard templates of ontwerp een offerte met je eigen logo en bedrijfskleuren.`,
    getMoneyTwoImage: professional,
}

const featureData = [
    {
        Icon: Credit,
        title: `Facturen`,
        text: `Stuur onbeperkt facturen in je eigen huisstijl. Gebruik onze gedesignde templates voor een professionele look.`,
        link: `Lees meer over facturen maken`,
        path: `facturen`,
    },
    {
        Icon: Credit,
        title: `Bonnen scannen`,
        text: `Scan gemakkelijk al je bonnetjes via de app. Nooit meer bonnetjes bewaren.`,
        link: `Lees meer over het scannen van bonnen`,
        path: `bonnen`,
    },
]

const FAQ = [
    {
        question: 'Hoe maak ik een offerte?',
        answer: `
            Stappenplan voor het maken van een offerte:

            <ul>
                <li>Breng de wensen van je opdrachtgever in kaart.</li>
                <li>Beschrijf je werkzaamheden.</li>
                <li>Maak de offerte en let erop dat je alle verplichte gegevens toevoegt.</li>
                <li>Verstuur de offerte in een pdf bestand.</li>
            </ul>

            De gegevens die je verplicht moet noteren kun je vinden bij de vraag ‘Wat moet er minimaal in een offerte?’.
        `,
    },
    {
        question: 'Hoe maak je een offerte aantrekkelijk?',
        answer: `
            Tips voor het maken van een aantrekkelijke offerte:

            <ul>
                <li>Besteed er aandacht aan. Een aantrekkelijke voorpagina trekt aandacht en laat zien dat je de klant belangrijk vind.</li>
                <li>Hou het kort & bondig en overzichtelijk. Mensen lezen niet graag lappen tekst. Gebruik opsommingen en subtitels.</li>
                <li>Overweeg het toevoegen van een kwalitatief goede foto.</li>
                <li>Trek de aandacht naar belangrijke punten. Overweeg het gebruik van dikgedrukt of onderstreept, arceren, etc.</li>
                <li>Gebruik je creativiteit. Een standaard offerte valt minder op.</li>
                <li>Zorg voor een goede uitstraling. Dik papier, mooi mapje.</li>
            </ul>
        `,
    },
    {
        question: 'Waar moet ik opletten bij een offerte opstellen?',
        answer: `
            Op de volgende punten bij het maken van een offerte:

            <ul>
                <li>Noteer alle verplichte gegevens.</li>
                <li>Maak de offerte aantrekkelijk.</li>
                <li>Zorg ervoor dat je offerte beter is dan je concurrentie.</li>
                <li>Bepaal je publiek en daarbij passend taalgebruik. Formeel, of juist wat informeler. Je taalgebruik moet ook passen bij je bedrijf.</li>
                <li>Verstuur de offerte in een pdf-bestand.</li>
            </ul>
        `,
    },
    {
        question: 'Hoe werkt een offerte?',
        answer: `
            Een offerte is een formeel voorstel met te leveren goederen of diensten met een prijs. In een offerte geef je een beschrijving van wat je precies gaat leveren. Potentiële klanten kunnen dit aanvragen en doen dit vaak bij meerdere partijen, om te kunnen vergelijken. Het is dus belangrijk dat je een betere offerte stuurt dan je concurrentie. Een offerte is een vrijblijvend voorstel en dus niet rechtsgeldig. Een klant kan op basis van de offerte besluiten het aanbod aan te nemen of af te wijzen.
        `,
    },
    {
        question: 'Wat moet er minimaal in een offerte?',
        answer: `
            De volgende gegevens zijn verplicht om toe te voegen:

            <ol>
                <li>Je bedrijfsgegevens.</li>
                <li>De gegevens van je klant.</li>
                <li>De datum van je offerte.</li>
                <li>Het offertenummer.</li>
                <li>De werkzaamheden die je levert.</li>
                <li>Je tarief of de prijs van het project.</li>
                <li>De geldigheidsduur van de offerte.</li>
                <li>Het recht op een aanbetaling tot 50% van het totaalbedrag.</li>
            </ol>

            Naast de verplichte gegevens kan je overwegen om bijvoorbeeld algemene voorwaarden of een verzoek om ontvangstbevestiging toe te voegen.
        `,
    },
    {
        question: 'Wat gebeurt er na een offerte?',
        answer: `
            De meeste klanten zullen meerdere offertes aanvragen bij verschillende opdrachtnemers, en deze vergelijken. Geef de klant dus de tijd om de offerte te overwegen. Na ongeveer een week kun je een offerte opvolgen. Dit doe je door middel van een mail, maar de voorkeur gaat uit naar een telefonisch gesprek.
            <br/><br/>
            Als je een offerte gaat opvolgen moet je op het volgende letten:

            <ul>
                <li>Bereid je goed voor op het gesprek zodat je de informatie kan geven die de klant eventueel gaat vragen.</li>
                <li>Kies het juiste moment; rond lunchtijd of aan het einde van de dag zijn geen goede momenten om te bellen.</li>
                <li>Wees behulpzaam, dan kun je twijfels bij de potentiële klant wegnemen. Denk mee aan de wensen van de klant.</li>
            </ul>

            De klant zal beslissen in te gaan op het aanbod of niet. Als een klant niet op het aanbod ingaat, kun je een mail sturen met de vraag waarom. Dit kan waardevolle feedback voor je bedrijf bevatten.
        `,
    },
]

const Offertes = () => (
    <Layout>
        <Meta
            keywords={['zzp', 'offertes', 'tellow']}
            path="/functies/offertes"
            title="Offertes | Tellow.nl"
            description="Snel online boekhouden met hét online boekhoudprogramma voor zzp'ers ✅ 100.000+ ondernemers gingen je voor ✅ Registreer gratis!"
        />
        <Header data={headerData} purpleBackground bottomImage />

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <TopHighlights data={topHighlightData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <Benefits data={benefitsData} />
            </ComponentContainer>
        </RelativeContainer>

        <ColoredBackgroundWithProps purple>
            <RelativeContainer>
                <ComponentContainer>
                    <HeaderAdvancedFunctionality data={headerAdvancedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <ColoredBackgroundWithProps gray100>
            <RelativeContainer>
                <ComponentContainer>
                    <AdvancedFunctionality data={advancedFunctionalityData} />
                </ComponentContainer>
            </RelativeContainer>
        </ColoredBackgroundWithProps>

        <RelativeContainer>
            <ComponentContainer>
                <FeatureLinks data={featureData} />
            </ComponentContainer>
        </RelativeContainer>

        <RelativeContainer>
            <ComponentContainer direction="column" padding={1.5}>
                <Title>Veelgestelde vragen</Title>
                {FAQ.map(({ question, answer }) => (
                    <Accordeon key={question} question={question}>
                        <div dangerouslySetInnerHTML={{ __html: answer }} />
                    </Accordeon>
                ))}
            </ComponentContainer>
        </RelativeContainer>
    </Layout>
)

export default Offertes
