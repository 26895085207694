import React from 'react'
import tw from 'twin.macro'

const Clear = () => (
    <svg css={tw`h-10 w-10 fill-current text-tellow-purple`} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.2001 3.6001C6.24532 3.6001 5.32964 3.97938 4.65451 4.65451C3.97938 5.32964 3.6001 6.24532 3.6001 7.2001V27.0001C3.6001 28.4323 4.16902 29.8058 5.18172 30.8185C6.19442 31.8312 7.56793 32.4001 9.0001 32.4001C10.4323 32.4001 11.8058 31.8312 12.8185 30.8185C13.8312 29.8058 14.4001 28.4323 14.4001 27.0001V7.2001C14.4001 6.24532 14.0208 5.32964 13.3457 4.65451C12.6706 3.97938 11.7549 3.6001 10.8001 3.6001H7.2001ZM9.0001 28.8001C9.47749 28.8001 9.93532 28.6105 10.2729 28.2729C10.6105 27.9353 10.8001 27.4775 10.8001 27.0001C10.8001 26.5227 10.6105 26.0649 10.2729 25.7273C9.93532 25.3897 9.47749 25.2001 9.0001 25.2001C8.52271 25.2001 8.06487 25.3897 7.72731 25.7273C7.38974 26.0649 7.2001 26.5227 7.2001 27.0001C7.2001 27.4775 7.38974 27.9353 7.72731 28.2729C8.06487 28.6105 8.52271 28.8001 9.0001 28.8001ZM18.0001 25.6375L26.8201 16.8175C27.495 16.1424 27.8741 15.2269 27.8741 14.2723C27.8741 13.3177 27.495 12.4022 26.8201 11.7271L24.2731 9.1801C23.598 8.5052 22.6825 8.12607 21.7279 8.12607C20.7733 8.12607 19.8578 8.5052 19.1827 9.1801L18.0001 10.3627V25.6375ZM28.8001 32.4001H16.3279L27.1279 21.6001H28.8001C29.7549 21.6001 30.6705 21.9794 31.3457 22.6545C32.0208 23.3296 32.4001 24.2453 32.4001 25.2001V28.8001C32.4001 29.7549 32.0208 30.6705 31.3457 31.3457C30.6705 32.0208 29.7549 32.4001 28.8001 32.4001Z"
            fill="currentColor"
        />
    </svg>
)

export default Clear
