import React from 'react'
import tw from 'twin.macro'
import styled, { css } from 'styled-components'

// Tailwind style
const style = {
    wrapper: tw`max-w-7xl py-6 mb-0 mr-auto px-4 sm:px-6 lg:px-8 -my-4`,
    bigTitle: tw`antialiased tracking-tight text-3xl font-semibold items-start mb-4`,
    text: tw`antialiased text-white opacity-80 max-w-prose text-sm font-normal mb-2`,
    img: tw`md:inline md:max-w-xs md:h-full mx-auto my-0 mb-0 p-0 filter drop-shadow-xl`,
}

const MainContainer = styled.div`
    display: flex;
    gap: 5rem;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        gap: 3rem;
    }
`

const MetaContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-direction: column;
`

const BookkeeperInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 0;

    img {
        width: 3rem;
    }

    h1 {
        font-size: ${({ theme }) => theme.font.size.base};
        font-weight: ${({ theme }) => theme.font.weight.bold};
    }
`

const Container = styled.div`
    ${({ props }) =>
        props?.alignment === 'left' &&
        css`
            text-align: left;
        `}
`

function HeaderAdvancedFunctionality({ data, alignment, bookkeepers }) {
    return (
        <Container alignment={alignment} css={style.wrapper}>
            {bookkeepers ? (
                <MainContainer>
                    <img css={style.img} src={data.firstStepImage} alt="Mobile dashboard with functionalities" />
                    <MetaContainer>
                        <BookkeeperInfo>
                            <h1>{data.title}</h1>
                        </BookkeeperInfo>
                        <p css={style.text}>{data.subtitlePartOne}</p>
                    </MetaContainer>
                </MainContainer>
            ) : (
                <div>
                    <h1 css={style.bigTitle}>{data.title}</h1>
                    <p css={style.text}>
                        {data.subtitlePartOne}
                        <br />
                        {data.subtitlePartTwo}
                    </p>
                </div>
            )}
        </Container>
    )
}

export default HeaderAdvancedFunctionality
